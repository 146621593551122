import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import ConnectLoading from 'src/components/@common/ConnectLoading/ConnectLoading';
import ErrorPage from 'src/components/ErrorPage/ErrorPage';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { switchActiveRole } from 'src/redux/user/slice';
import { Status } from 'src/types/Status';

interface ProtectedRouteProps {
  requiresAuth: boolean,
  allowedRoles: string[] | '*',
}

const ProtectedRoute = ({
  requiresAuth, allowedRoles = [],
}: ProtectedRouteProps) => {
  const { t } = useTranslation('error');
  const user = useAppSelector((state) => state.user);
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  if (!requiresAuth) return <Outlet />;

  if (
    !auth.initialized
    || auth.status === Status.LOADING
    || user.status === Status.LOADING
    || !user.user
  ) return <ConnectLoading dataTestid="ProtectedRoute-loading" />;

  if (
    auth.status === Status.FAILED
    || user.status === Status.FAILED
  ) return <ErrorPage message={t('auth')} />;

  const userRolesAllowed = user.user.roles.filter((role) => allowedRoles.includes(role));
  const allowUser = allowedRoles === '*' || userRolesAllowed.length;

  if (!allowUser) return <ErrorPage message={t('unallowedRole')} />;

  /**
   * if user has an allowed role but an unallowed role is active,
   * switch active role to the first allowed role in the user roles array
   */
  if (!userRolesAllowed.includes(user.user.activeRole)) {
    dispatch(switchActiveRole(userRolesAllowed[0]));
  }

  return <Outlet />;
};

export default ProtectedRoute;
